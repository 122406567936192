import React from "react"
import styled from "styled-components"

import Ad from "../adsense/index"

const AdWrapper = styled.div`
  overflow: hidden;
  margin: 80px 16px 16px 16px;
  flex: 0 1 auto;
  text-align: center;

  @media (max-width: 1364px) {
    display: none;
  }
`

export const AdLayout = styled.div`
  display: flex;

  @media (max-width: 1364px) {
    display: unset;
  }
`

export const AdBanner = ({ slot }) => {
  return (
    <AdWrapper>
      <Ad slot={slot} />
    </AdWrapper>
  )
}
