import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import MarkdownView from "react-showdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PostHeader,
  PostContainer,
  PostTags,
  PostTag,
  Tag,
  PostImageWrapper,
} from "../components/styled/post.js"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const ArticleTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={`${data.strapiArticle.title} - FGCharts`}
      description={data.strapiArticle.description}
    />
    <AdLayout>
      <AdBanner slot="6517054732" />
      <Container style={{ flex: "10 0 auto" }}>
        <div>
          <PostHeader>
            <h2 style={{ marginBottom: 16 }}>{data.strapiArticle.title}</h2>
            <p style={{ marginBottom: 0 }}>
              {`${data.strapiArticle.author} - ${moment(
                data.strapiArticle.createdAt
              ).format("LL")}`}
            </p>
            <PostTags>
              {data.strapiArticle.tags.map((tag, index) => (
                <PostTag key={index} right={data.strapiArticle.tags.length > 1}>
                  <Tag>{tag}</Tag>
                </PostTag>
              ))}
            </PostTags>
          </PostHeader>
          <PostImageWrapper>
            <Img
              fluid={data.strapiArticle.featuredImage.childImageSharp.fluid}
            />
          </PostImageWrapper>
          <PostContainer>
            <MarkdownView
              markdown={data.strapiArticle.content}
              options={{ table: true, emoji: true }}
            />
          </PostContainer>
        </div>
      </Container>
      <AdBanner slot="6517054732" />
    </AdLayout>
  </Layout>
)
export default ArticleTemplate
export const query = graphql`
  query ArticleTemplate($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      id
      title
      content
      author
      tags
      description
      createdAt
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 780, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
