import React from "react"
import AdSense from "react-adsense"

const Ad = ({ slot }) => (
  <AdSense.Google
    client="ca-pub-6382843457107483"
    slot={slot}
    format=""
    responsive="true"
    style={{ display: "block", width: 260, height: 500 }}
  />
)

export default Ad
